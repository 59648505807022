import axios from 'axios'
import qs from "qs";
import { getLocalStorageStr } from './common'
import { apiUrl, storeUrl, identity } from './config'

const instance = axios.create({
    baseURL: apiUrl,
    timeout: 10000
})

export const get = async (url, data = {}) => {
    let res = await initRequestHeader('get', url, data);
    data = res.data;
    return new Promise((resolve, reject) => {
        instance.get(res.url, { params: data, headers: res.headers }).then((response) => {
            if (response.data.state == 266) {
                //清除全部缓存
                // localStorage.clear();
                //跳转登录页
                let des_url = window.location.href;
                if (des_url.indexOf('redirect=') != -1) {
                    des_url = des_url.substring(0, des_url.indexOf('redirect=') - 1);
                }
                window.location.href = storeUrl + '/user/login?redirect=' + encodeURIComponent(des_url);
                window.reload();
            } else {
                resolve(response.data)
            }
        }, err => {
            reject(err)
        })
    })
}

export const post = async (url, data = {}, type = 'urlencoded') => {
    let res = await initRequestHeader('post', url, data, type);
    return new Promise((resolve, reject) => {
        instance.post(res.url, res.data, {
            headers: res.headers
        }).then((response) => {
            if (response.data.state == 266) {
                //清除全部缓存
                // localStorage.clear();
                //跳转登录页
                let des_url = window.location.href;
                if (des_url.indexOf('redirect=') != -1) {
                    des_url = des_url.substring(0, des_url.indexOf('redirect=') - 1);
                }
                window.location.href = storeUrl + '/user/login?redirect=' + encodeURIComponent(des_url);
                window.reload();
            } else {
                resolve(response.data)
            }
        }, err => {
            reject(err)
        })
    })
}

/**
 * 初始化请求头和数据
 * @zjf-2020-12-25
 */
const initRequestHeader = async (method, url, data = {}, type = 'urlencoded') => {
    let result = {};
    let headers = {};

    //用户登录状态下，每次更新refresh_token58分钟之后需要更新access_token
    if (getLocalStorageStr('access_token')) {
        let cur_time = new Date().getTime();
        if (cur_time - getLocalStorageStr('time') * 1 > 58 * 60 * 1000) {
            let param = new FormData();
            param.append('grant_type', 'refresh_token');
            param.append('refresh_token', getLocalStorageStr('sld_refresh_token'));
            // param.append('refresh_token', 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjo1LCJ1c2VyX25hbWUiOiJzbG9kb24wMyIsInNjb3BlIjpbImFsbCJdLCJ2ZW5kb3Jfcm9sZV9pZCI6MiwiYXRpIjoiYjA4NDYzZjItZTZlMC00YWI0LTk2OGQtOWVmOTdmZWI2ZDFmIiwiZXhwIjoxNjE3NzgyOTQwLCJ1dWlkIjoiMDI0OGQyZDgtZmRlNC00ZDJjLWIxZmYtNDU4ZTEzYzhkZTQ1IiwianRpIjoiM2E4ZGVlMjAtYzI5YS00YzI0LThjMWUtY2UxNDc2YjQwMTMzIiwid2ViSWRlbnRpZnkiOiJzZWxsZXIiLCJjbGllbnRfaWQiOiJzZWxsZXIifQ.AIJ-piFyT0iTJns9XFg0vs6mXAC0cxvN_WNU2Pk3qWR_VTQ1At0BNAzizu3vFbURPGDg8Pi-nWQG5RDaAnuwio-uTT1dIvRayBiw37HDkUuqrN-gYk915OVvb0GYEHSM_szt2DM_FrRUv54CV0vOhqdO57Y03DKrqPciPvJyaSU')
            await instance.post(`v3/${identity}Login/oauth/token`, param, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': 'Basic VVcxS2FsRlhVblJoVnpROTpVMjFHTWxsVlFrUmlNMEkxVlcxc2JtRklVa0ZWTW5oMldrYzVkUT09'
                }
            }).then((response) => {
                if (response.data.state == 200) {
                    localStorage.setItem('sld_token', response.data.data.access_token);
                    localStorage.setItem('sld_refresh_token', response.data.data.refresh_token);
                    localStorage.setItem('time', new Date().getTime().toString());//存储refresh_token更新时间
                }
            }, err => {
                console.log('更新access_token出错：', err);
            })
        }
    }

    if (method == 'post') {
        if (type == 'urlencoded') {
            headers = { 'Content-Type': 'application/x-www-form-urlencoded' };
            data = qs.stringify(data);
        } else if (type == 'json') {
            headers = { 'Content-Type': 'application/json' };
            data = JSON.parse(JSON.stringify(data));
        } else if (type == 'form') {//form-data
            headers = { 'Content-Type': 'multipart/form-data' };
            let tmp_data = new FormData();
            Object.keys(data).forEach((item) => {
                tmp_data.append(item, data[item])
            });
            data = tmp_data;
        }
    }

    if (url.indexOf(`v3/${identity}Login/oauth/token`) > -1) {
        headers.Authorization = 'Basic VVcxS2FsRlhVblJoVnpROTpVMjFHTWxsVlFrUmlNMEkxVlcxc2JtRklVa0ZWTW5oMldrYzVkUT09';
    } else {
        let token = getLocalStorageStr('sld_token');
        // let token = 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjo0LCJ1c2VyX25hbWUiOiJzbG9kb24wMiIsInNjb3BlIjpbImFsbCJdLCJ2ZW5kb3Jfcm9sZV9pZCI6MiwiZXhwIjoxNjMyNzQ3NzYwLCJ1dWlkIjoiOWMwODE1ZWUtNDY1MS00ZjdhLWFmODctNDk2MzgwNWFmN2RiIiwianRpIjoiZWE0MWI3YTgtZGZhMi00MzYxLTgwNTQtNmMzMWIxNjcxODUzIiwid2ViSWRlbnRpZnkiOiJzZWxsZXIiLCJjbGllbnRfaWQiOiJzZWxsZXIifQ.HwJ7nHWm3RY6abU3QPMYXisIe6JH0W_vftP4z2j1iRo2NrsqLeLp4Yich0EnwOKkjWruP0lZwnCVtie_N0oC-cPSALycHAsJLTLPA3e7IzuEWmEJWlamFc9rMwGPQk0X1WvgRHajERGivJt5Ki7iC4rv4239Ldaidkh5Tp3WH-Q'
        headers.Authorization = 'Bearer ' + token;

    }

    result = { url, data, headers };
    return result;
}
